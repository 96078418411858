<template>
  <layout-view navTitle="Фильтры">
    <template v-slot:nav>
      <div class="px-4">
        <date-interval name="Дата создания" :valueFrom.sync="dateFrom" :valueTo.sync="dateTo" valueFromMinus="month:1" />

        <user-autocomplete-multiple name="Клиенты" :select.sync="usersClientSelected"/>
        <user-autocomplete-multiple name="Специалисты" _type="spec" :select.sync="usersSpecSelected"/>
   
        <date-interval name="Планируемая дата" :valueFrom.sync="plannedDateFrom" :valueTo.sync="plannedDateTo"/>

        <label-view title="Статус">
          <template v-slot:content>
            <v-list class="pa-0" nav>
              <v-list-item
                  v-for="key in Object.keys(statuses)" :key="key"
                  @click="statusesSelected.filter((v)=>v===key).length===0 ? statusesSelected.push(key) : statusesSelected = statusesSelected.filter((v)=>v!==key);"
                  class="mb-0"
              >
                <v-checkbox :input-value="isCheckboxChecked(key)" :value="statusesSelected.filter((v)=>v===key).length>0" class="mb-2" color="primary" dense hide-details="auto" />
                <div>
                  <v-icon size="18" :color="statuses[key].color" class="mr-2">{{ statuses[key].icon }}</v-icon>
                  {{ statuses[key].name }}
                </div>
              </v-list-item>
            </v-list>
          </template>
        </label-view>

        <direction-autocomplete-multiple :select.sync="directions" :directionCatId="13" class="mb-4 mt-1"/>

        <checkbox-field class="mb-4" name="Есть просроченные услуги" :value.sync="isOverDate"/>

        <checkbox-field name="Без направления" :value.sync="emptyDirections"/>

      </div>
    </template>
    <template v-slot:content="{height}">
      <n-table-view
        ref="table"
        name="Список"
        :genRequestBuilder="genRequestBuilder"
        :height="height"
        :headers="[
            { text: 'ID', value: 'id', sortable: true, width:'auto'},
            { text: 'Название', class:'text-no-wrap', value: 'name', sortable: true, width:400 },
            { text: 'Клиент', class:'text-no-wrap', value: 'user.lastName', sortable: true, width:200},
            { text: 'Специалист', class:'text-no-wrap', value: 'userSpec.lastName', sortable: true, width:200},
            { text: 'Назначен', class:'text-no-wrap', value:'begin', sortable: true},
            { text: 'План', class:'text-no-wrap', value:'plannedDate', sortable: true},
            { text: 'Цель', class:'text-no-wrap', value:'achievedDate', sortable: true},
            { text: 'Комментарий', class:'text-no-wrap', sortable: false, width:200},
            { text: 'Статус', class:'text-no-wrap', value:'status', sortable: true},
            { text: 'Статус оплаты', class:'text-no-wrap', class:'text-no-wrap', sortable: false},
            { text: 'Личный счёт', class:'text-no-wrap', class:'text-no-wrap', sortable: false},
            { text: 'Начислено', class:'text-no-wrap', sortable: false},
            { text: 'Отменено', class:'text-no-wrap', sortable: false},
            { text: 'Оплачено', class:'text-no-wrap', sortable: false},
            { text: 'Осталось', class:'text-no-wrap', sortable: false},
            { text: 'Направление', class:'text-no-wrap', value: 'directions.name', sortable: false, width:200},
        ]"  
      >
    
      <template v-slot:item="{ item }">
          <tr @click="medPlanId=parseInt(item.id)" :style="item.isOverDate ? {background:'#FFEBEE'}: ''" :title="item.isOverDate ?'В медплане есть просроченные услуги': null">
            <td class="text-wrap">{{ item.id }}</td>
            <td>
              <div class="d-flex align-center">
                <v-icon v-if="item.isOverDate" class="mr-2" color="red" small>mdi-alert-circle</v-icon> 
                <span>{{$tools.isEmpty(item.name) ? 'Без названия' : item.name}}</span>
              </div>
            </td>
            <td :data-print="$tools.userFullName(item.user)">
              <div class="d-flex align-center">
                <span class="mr-1">{{$tools.userFullName(item.user,true)}}</span>
                <v-btn
                      text
                      icon 
                      x-small
                      @click.stop="$tools.copyToClipboard($tools.userFullName(item.user));$store.commit('alertSuccess', 'ФИО скопировано');"
                    >
                  <v-icon small>mdi-content-copy</v-icon> 
                </v-btn>
                <v-btn 
                    @click.stop
                    x-small
                    icon depressed :href="'/panel/user/client/'+item.user.id+'/'" target="_blank">
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-btn>
              </div>
            </td>
            <td v-if="item.userSpec" :data-print="$tools.userFullName(item.userSpec)">
              <div class="d-flex align-center">
                <span class="mr-1">{{$tools.userFullName(item.userSpec, true)}}</span>
                <v-btn
                      text
                      icon 
                      x-small
                      @click.stop="$tools.copyToClipboard($tools.userFullName(item.userSpec));$store.commit('alertSuccess', 'ФИО скопировано');"
                    >
                  <v-icon small>mdi-content-copy</v-icon> 
                </v-btn>
                <v-btn 
                    @click.stop
                    x-small
                    icon depressed :href="'/panel/user/spec/'+item.userSpec.id+'/'" target="_blank">
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-btn>
              </div>  
            </td>
            <td v-else>-</td>

            <td class="text-no-wrap">{{item.begin===null ? '-' : $tools.date(item.begin)}}</td>
            <td class="text-no-wrap">{{item.plannedDate===null ? '-' : $tools.date(item.plannedDate)}}</td>
            <td class="text-no-wrap">{{item.achievedDate===null ? '-' : $tools.date(item.achievedDate)}}</td>
            <td>{{$tools.isEmpty(item.comment) ? '-' : item.comment}}</td>


            <td class="text-center">
              <tooltip-view v-if="item.status === 'active'" top content="Активен">
                  <template v-slot:action>
                    <v-icon class="mr-2" size="18" color="error" style="margin-top:-1px">mdi-information-outline</v-icon> <span class="d-none">Активен</span>
                  </template>
              </tooltip-view>

              <tooltip-view v-else-if="item.status === 'done'" top content="Выполнен">
                  <template v-slot:action>
                    <v-icon class="mr-2" size="18" color="warning" style="margin-top:-1px">mdi-check</v-icon> <span class="d-none">Выполнен</span>
                  </template>
              </tooltip-view>

              <tooltip-view v-else-if="item.status === 'achieved'" top content="Цель достигнута">
                  <template v-slot:action>
                    <v-icon class="mr-2" size="18" color="purple" style="margin-top:-1px">mdi-check</v-icon> <span class="d-none">Цель достигнута</span>
                  </template>
              </tooltip-view>
             
              <tooltip-view v-else top content="Завершен">
                  <template v-slot:action>
                    <v-icon class="mr-2" size="18" color="success" style="margin-top:-1px">mdi-check</v-icon> <span class="d-none">Завершён</span>
                  </template>
              </tooltip-view>
            </td>

            <td class="text-no-wrap" >
              <v-chip v-if="item.summary.wait===0" class="status d-block text-center" small color="success">Оплачено</v-chip>
              <v-chip v-else class="status d-block text-center" small color="warning">Не оплачено</v-chip>
            </td>

            <td class="text-no-wrap">{{$tools.price(item.user.accountBalance, '-')}}</td>
            <td class="text-no-wrap">{{$tools.price(item.summary.total, '-')}}</td>

            <td class="text-no-wrap">{{$tools.price(item.summary.cancel, '-')}}</td>

            <td class="text-no-wrap">{{$tools.price(item.summary.success, '-')}}</td>

            <td class="text-no-wrap">{{$tools.price(item.summary.wait, '-')}}</td>
            
            <td>{{directionNames(item.directions)}}</td>

     
          </tr>
        </template>
      </n-table-view>
      <med-plan :medPlanId.sync="medPlanId" @cancel="$refs.table.load();"/>
    </template>
  </layout-view>
</template>

<script>
import LayoutView from "@/components/LayoutView";
import NTableView from "@/componentsV2/base/NTableView.vue";
import CheckboxField from "@/componentsV2/base/CheckboxField.vue";
import LabelView from "@/components/LabelView";
import moment from "moment";
import DateInterval from "@/componentsV2/base/DateInterval.vue";
import MedPlan from "@/views/MedPlan/MedPlan";
import UserAutocompleteMultiple from "@/componentsV2/custom/UserAutocompleteMultiple.vue";
import {Entity_MedPlan, Entity_User, Entity_UserProfile, Entity_Direction} from "../../../EntityStoreCacheService";
import TooltipView from "@/components/TooltipView";
import DirectionAutocompleteMultiple from "@/componentsV2/custom/DirectionAutocompleteMultiple.vue";


export default {
  components: {
    NTableView,
    LabelView, 
    LayoutView,
    MedPlan,
    DateInterval,
    CheckboxField,
    UserAutocompleteMultiple,
    TooltipView,
    DirectionAutocompleteMultiple
  },
  props: ['currentUser'],
  data:()=>({
    dateFrom:null,
    dateTo:null,
    plannedDateFrom: null,
    plannedDateTo: null,
    statuses:{
      'active':{'name':'Активен', 'color': 'error', 'icon': "mdi-information-outline"},
      'achieved':{'name':'Цель достигнута', 'color': 'purple', 'icon': "mdi-check"},
      'done':{'name':'Выполнен', 'color': 'warning', 'icon': "mdi-check"},
      'complete':{'name':'Завершён', 'color': 'success', 'icon': 'mdi-check'}},
    statusesSelected:[],
    usersClientSelected:[],
    usersSpecSelected:[],
    medPlanId:null,
    query:null,
    isOverDate:false,
    directions: [],
    emptyDirections: false
  }),
  mounted(){
    if (typeof this.currentUser !== 'undefined'){
      new Entity_User()
        .selects(Entity_User.id)
        .selects(Entity_User.firstName)
        .selects(Entity_User.lastName)
        .selects(Entity_User.secondName)
        .selects(Entity_User.type)
        .selects([Entity_User.userProfile,Entity_UserProfile.gender].join('.'))
        .selects([Entity_User.userProfile,Entity_UserProfile.birth].join('.'))
        .filterAnd([Entity_User.entity,Entity_User.id].join('.')+'=?',parseInt(this.$store.state.user.id))
        .request((r)=>{
          this.usersSpecSelected = r.items;
          this.$refs.table.load();
        }, (e)=>{
          console.error(e.response.data.message);
        })
    }
  },
  computed: {
    directionNames() {
      return (items) => {
        if (items.length>0) {
          let directions = [];
          items.forEach(i => {
            directions.push(i.name);
          });
          return `${directions.join()}`;
        } else return '-';
      }
    },
  },
  watch:{
    dateFrom(){
      this.$refs.table.load();
    },
    dateTo(){
      this.$refs.table.load();
    },
    plannedDateFrom(){
      this.$refs.table.load();
    },
    plannedDateTo(){
      this.$refs.table.load();
    },
    statusesSelected(){
      this.$refs.table.load();
    },
    usersClientSelected(){  
      this.$refs.table.load();
    },
    usersSpecSelected() {
      this.$refs.table.load();
    },
    isOverDate() {
      this.$refs.table.load();
    },
    directions() {
      this.$refs.table.load();
    },
    emptyDirections(){
      this.$refs.table.load();
    }
  },
  methods:{
    genRequestBuilder(page, onPage, sortFields){
      let rb = new Entity_MedPlan()
        .selects(Entity_MedPlan.id)
        .selects(Entity_MedPlan.name)
        .selects(Entity_MedPlan.begin)
        .selects(Entity_MedPlan.end)
        .selects(Entity_MedPlan.plannedDate)
        .selects(Entity_MedPlan.achievedDate)
        .selects(Entity_MedPlan.comment)
        .selects(Entity_MedPlan.status)
        //user
        .selects([Entity_MedPlan.user,Entity_User.id].join('.'))
        .selects([Entity_MedPlan.user,Entity_User.firstName].join('.'))
        .selects([Entity_MedPlan.user,Entity_User.lastName].join('.'))
        .selects([Entity_MedPlan.user,Entity_User.secondName].join('.'))
        //userSpec
        .selects([Entity_MedPlan.userSpec,Entity_User.id].join('.'))
        .selects([Entity_MedPlan.userSpec,Entity_User.firstName].join('.'))
        .selects([Entity_MedPlan.userSpec,Entity_User.lastName].join('.'))
        .selects([Entity_MedPlan.userSpec,Entity_User.secondName].join('.'))

        //directions
        .selects([Entity_MedPlan.directions, Entity_Direction.name].join('.'))
        
        //virtual
        .virtual(Entity_MedPlan.virtual_isOverDate)
        .virtual(Entity_MedPlan.virtual_summary);


        rb.page(page).onPage(onPage)

        if(this.dateFrom) {  
          rb.filterAnd([Entity_MedPlan.entity, Entity_MedPlan.begin].join('.')+'>=?',moment(this.dateFrom, 'DD-MM-YYYY').format('YYYY-MM-DD 00:00:00'));
        } 

        if(this.dateTo) {  
          rb.filterAnd([Entity_MedPlan.entity, Entity_MedPlan.begin].join('.')+'<=?',moment(this.dateTo, 'DD-MM-YYYY').format('YYYY-MM-DD 23:59:59'));
        }

        if(this.plannedDateFrom) {  
          rb.filterAnd([Entity_MedPlan.entity, Entity_MedPlan.plannedDate].join('.')+'>=?',moment(this.plannedDateFrom, 'DD-MM-YYYY').format('YYYY-MM-DD 00:00:00'));
        } 

        if(this.plannedDateTo) {  
          rb.filterAnd([Entity_MedPlan.entity, Entity_MedPlan.plannedDate].join('.')+'<=?',moment(this.plannedDateTo, 'DD-MM-YYYY').format('YYYY-MM-DD 23:59:59'));
        }
   
        if (this.statusesSelected.length>0) {
          rb.filterAnd([Entity_MedPlan.entity, Entity_MedPlan.status].join('.')+' in (?)',this.statusesSelected);
        }

        if (this.usersClientSelected.length>0) {
          rb.filterAnd([Entity_MedPlan.entity, Entity_User.entity, Entity_User.id].join('.')+' in (?)', this.queryArray(this.usersClientSelected))
        }

        if (this.usersSpecSelected.length>0) {
          rb.filterAnd([Entity_MedPlan.entity, Entity_MedPlan.userSpec, Entity_User.id].join('.')+' in (?)',  this.queryArray(this.usersSpecSelected))
        }

        if (this.isOverDate) {
          rb.filterAdd(['overDate'].join('.'));
        }

        if (this.directions.length>0) {
          rb.filterAnd([Entity_MedPlan.entity, Entity_MedPlan.directions, Entity_Direction.id].join('.')+' in (?)', this.queryArray(this.directions))
        }
        if (this.emptyDirections) {
          rb.filterAnd([Entity_MedPlan.entity, Entity_MedPlan.directions, Entity_Direction.id].join('.')+' is null');
        }
        

    
        sortFields.forEach(v=>{
          rb.order(v.field, v.isAsc)
        })

        return rb;
    },
    isCheckboxChecked(key) {
      if (this.statusesSelected.includes(key)) {
        return true;
      } else {
        return false;
      }
    },
    queryArray(items,key='id') {
      let arr = [];
      items.forEach(s=>{
        arr.push(s[key]);
      });
      return arr;
    }
  }
}
</script>